import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { WWCharacter } from '../common/components/ww-character';

interface IProps {
  name: string;
}

export const WWEchoStatsComparison: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'phoebe' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Cosmic Ripples S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Phoebe 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,626 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Phoebe 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '95.46%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">34,010 DPS</span>
                          <span className="percent">95.46%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Luminous Hymn S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Phoebe 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">42,562 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Phoebe 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '98.26%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">41,821 DPS</span>
                          <span className="percent">98.26%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'roccia' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Abyss Surges S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Roccia 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">26,871 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Roccia 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '93.42%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">25,102 DPS</span>
                          <span className="percent">93.42%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Tragicomedy S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Roccia 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,363 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Roccia 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.36%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,221 DPS</span>
                          <span className="percent">96.36%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'carlotta' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Static Mist S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Carlotta 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,315 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Carlotta 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '98.78%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">37,847 DPS</span>
                          <span className="percent">98.78%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (The Last Dance S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Carlotta 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">45,083 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Carlotta 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.59%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">44,898 DPS</span>
                          <span className="percent">99.59%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'camellya' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Emerald of Genesis S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,756 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.56%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,621 DPS</span>
                          <span className="percent">99.56%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.64%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">29,724 DPS</span>
                          <span className="percent">96.64%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.16%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">29,574 DPS</span>
                          <span className="percent">96.16%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Red Spring S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,782 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.24%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,509 DPS</span>
                          <span className="percent">99.24%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '98.8%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,354 DPS</span>
                          <span className="percent">98.8%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Camellya 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '98.65%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,300 DPS</span>
                          <span className="percent">98.65%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'xiangli-yao' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Abyss Surges S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,101 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '97.27%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,251 DPS</span>
                          <span className="percent">97.27%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '93.04%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">28,935 DPS</span>
                          <span className="percent">93.04%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '91.75%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">28,536 DPS</span>
                          <span className="percent">91.75%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Verity's Handle S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,803 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.35%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,563 DPS</span>
                          <span className="percent">99.35%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '97.88%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,023 DPS</span>
                          <span className="percent">97.88%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.26%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,428 DPS</span>
                          <span className="percent">96.26%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'zhezhi' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Cosmic Ripples S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">26,845 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '93.38%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">25,067 DPS</span>
                          <span className="percent">93.38%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Rime-Draped Sprouts S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,254 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.31%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,975 DPS</span>
                          <span className="percent">96.31%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
