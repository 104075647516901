import React from 'react';
import { WWEchoEntry } from '../echoes/ww-echo';
import { WWSet } from '../common/components/ww-set';

interface IProps {
  name: string;
  role: string;
  element: string;
}

export const WWMainEchoesProfile: React.FC<IProps> = ({
  name,
  element,
  role
}) => {
  const infoBell =
    "While nowadays a worse option for the Rejuvenating Glow set for damage than the Fallacy of No Return due to a shorter buff uptime, only granting 10% DMG Bonus for 15 seconds, the Bell-Borne Geochelone remains a good choice if you don't have a good Fallacy to use for the set. It also has increased survivability by blocking 50% of the damage from a max of 3 hits if you desire to have that in your team.";
  const infoFelacy =
    'Grants 10% increased Energy Regeneration for the wearer and a team wide 10% ATK boost on use for 20 seconds. A strong option for those looking to generate as much energy as possible or who favor a more permanent party buffing effect compared to Bell-Borne Geochelone.';
  const infoHeron =
    'Supportive Echo that can be run at the cost of a sizeable chunk of damage for the character who equips, trading personal gain for team benefits. Should always be used and immediately switch cancelled right before performing an Outro, leading to another DPS. When done so will grant the next character switching in 12% increased DMG% which when combined with the 5P Moonlit set which also grants 22.5% ATK% under the same conditions makes for a sizeable boost. While the echo does lower the damage of whoever wears it, it grants a massive chunk of energy on use usually saving 1 or sometimes even 2 Energy Regen sub stats that may otherwise be needed by the wearer allowing for some damage to be recovered.';
  const infoMech =
    'Very easy echo to use that should be used at the very beginning of the burst window right after the Intro. Thanks  to its instant cast there is no thinking required and you can enjoy your ATK% boost and some nice burst damage.';
  const infoLampy =
    'Easy to use Echo that deals decent total damage in a small AoE, has good resonance energy generation and on top of all that inflicts a small crowd control freeze effect on hit enemies. The gigantic downside of this Echo is that, unlike most other powerful echoes, you must fully use its entire effect with no good opportunity to perform a switch cancel. This one flaw means you will be spending close to 3 seconds using this Echo each activation.';
  const infoBerr =
    "Gives the same buffs as its Nightmare variant, but only after being transformed into, for a duration of 15 seconds. This Echo compensates by dealing close to double the damage of the Nightmare and giving more Energy to the character using it, but it's more difficult to incorporate into rotations, as it needs to be Swap Cancelled after the first hit to be used smoothly while making sure your character gets its buffs.";
  const infoBerrNM =
    "Offers an unconditional 12% Aero DMG and Heavy ATK boost when used as the Main Echo, and additionally is a Summon-type echo making it easy to use in any character's rotation, which often makes it the best option for any character using the set.";
  return (
    <>
      <p>
        <strong>Best Echo Sets</strong> show the best sets that support the main
        character playstyle and work out of the box while the{' '}
        <strong>Special Echo Sets</strong> either are situational, support an
        alternative playstyle or are targeted toward min-maxers. The percentage
        numbers showcase the relative DPS increase when you take into account
        the set bonus only. For some characters there's no DPS (or its
        equivalent) available and in that case we listed the sets in the order
        of priority.
      </p>
      <p>
        <strong className="red">Important!</strong> The 2.0 introduced quite a
        lot of new Echoes and we need more time to properly test out all of them
        (especially considering the past bugs related to them that made some
        stronger, some weaker).{' '}
        <strong>The builds below are provisional</strong> and we will be
        updating them over the next few days as we do our testing - so before
        you use all your stamina on the new Echoes, please have a bit of
        patience.
      </p>
      <div className="build-tips">
        {/* Supports*/}
        {role === 'Support' && name != 'yuanwu' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Rejuvenating Glow" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Healing% boost and an most
                importantly a 15% ATK% boost for the entire team, lasting 30
                seconds whenever you heal yourself or any of your allies. This
                bonus can be triggered by character abilities and weapon
                effects, is easy to keep active 100% of the time and is a big
                help for all party members.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                {name === 'the-shorekeeper' && (
                  <li>
                    <WWEchoEntry
                      name="Fallacy of No Return"
                      level={[5]}
                      mode="icon"
                      inline
                    />{' '}
                    - Grants Shorekeeper her 10% bonus Energy Regen on use for
                    20 seconds which counts towards the 250% requirement she
                    must meet, easing pressure on her gear, weapon and sub
                    stats. On top of this, the echo also grants the entire team
                    a 10% boost to ATK for 20 seconds and as a bonus also deals
                    damage based on the wearers HP instead of ATK or DEF meaning
                    it will slightly boost Shorekeeper's damage contribution.
                    Not a clear cut upgrade over Bell-Borne Geochelone but a
                    good alternative for those wishing for more flexibility in
                    Shorekeeper's gear or some extra damage.
                  </li>
                )}
                {name === 'youhu' && (
                  <li>
                    <WWEchoEntry
                      name="Fallacy of No Return"
                      level={[5]}
                      mode="icon"
                      inline
                    />{' '}
                    - One of two main Echo choice for Youhu. Grants 10%
                    increased Energy Regeneration for Youhu herself and a team
                    wide 10% ATK boost on use for 20 seconds. A strong option
                    for those looking to generate as much energy as possible on
                    Youhu or who favor a more permanent party buffing effect
                    compared to Bell-Borne Geochelone.
                  </li>
                )}
                {name != 'youhu' && name != 'the-shorekeeper' && (
                  <li>
                    <WWEchoEntry
                      name="Fallacy of No Return"
                      level={[5]}
                      mode="icon"
                      inline
                    />{' '}
                    - {infoFelacy}
                  </li>
                )}
                <li>
                  <WWEchoEntry
                    name="Bell-Borne Geochelone"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBell}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'yuanwu' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Rejuvenating Glow" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Healing% boost and an most
                importantly a 15% ATK% boost for the entire team, lasting 30
                seconds whenever you heal yourself or any of your allies. This
                bonus can be triggered by character abilities and weapon
                effects, is easy to keep active 100% of the time and is a big
                help for all party members.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Fallacy of No Return"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoFelacy}
                </li>
                <li>
                  <WWEchoEntry
                    name="Bell-Borne Geochelone"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBell}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Empyrean Anthem" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers an unconditional 10% Energy Regen% and 80% Coordinated
                DMG boost. Also grants a passive supportive effect which
                triggers when the wearer of this set critically strikes with a
                Coordinated attack - this effect grants the on field Resonator
                an ATK% boost regardless if they're the wearer of the set or
                not.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Tempest Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Resonance Skill damage and are looking to use either the 5P
                  Empyrean Anthem Echo or 5P Void Thunder set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
        {/* Hybrids */}
        {name === 'rover-spectro' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Rejuvenating Glow" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Healing% boost and an most
                importantly a 15% ATK% boost for the entire team, lasting 30
                seconds whenever you heal yourself or any of your allies. This
                bonus can be triggered by character abilities and weapon
                effects, is easy to keep active 100% of the time and is a big
                help for all party members.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Fallacy of No Return"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoFelacy}
                </li>
                <li>
                  <WWEchoEntry
                    name="Bell-Borne Geochelone"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBell}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'sanhua' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'lumi' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Void Thunder" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                If you want to play Lumi as Main DPS (which isn't really that
                optimal), you can use the Void Thunder set on her.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Thundering Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Liberation
                  DMG boost when placed in the main Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Liberation damage and are looking to use the 5P Void Thunder
                  set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'roccia' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Midnight Veil" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Havoc DMG% boost alongside adding a
                large Havoc Damage hit to the Outro of the wearer. Also grants
                the incoming resonator a Havoc DMG% boost when the wearer uses
                their Outro.{' '}
                <strong>
                  The set is better than Moonlit Clouds if and only if the
                  equipped character's Echoes have endgame investment (with 9 or
                  10 CRIT substats total) and the character has a 5-star weapon
                  equipped. If both conditions aren't met, Moonlit Clouds is a
                  better choice.{' '}
                </strong>
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Havoc DMG and 12% Heavy Attack
                  DMG boost when placed in the Main Echo slot, making it a solid
                  choice for Havoc focused resonators who emphasize Heavy Attack
                  damage and are looking to use the 5P Midnight Veil set bonus.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'yangyang' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Sierra Gale" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Aero DMG% boost and an additional 30%
                Aero DMG% after using the intro lasting 15 seconds for a grand
                total of 40% additional Aero damage for most of your damage
                window.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerrNM}
                </li>
                <li>
                  <WWEchoEntry
                    name="Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerr}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'zhezhi' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Empyrean Anthem" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers an unconditional 10% Energy Regen% and 80% Coordinated
                DMG boost. Also grants a passive supportive effect which
                triggers when the wearer of this set critically strikes with a
                Coordinated attack - this effect grants the on field Resonator
                an ATK% boost regardless if they're the wearer of the set or
                not.{' '}
                <strong>
                  The set is better than Moonlit Clouds if and only if the
                  equipped character's Echoes have endgame investment (with 9 or
                  10 CRIT substats total) and the character has a 5-star weapon
                  equipped. If both conditions aren't met, Moonlit Clouds is a
                  better choice.{' '}
                </strong>
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry name="Hecate" level={[5]} mode="icon" inline /> -
                  Offers a 40% unconditional Coordinated Attack DMG% boost when
                  placed in the Main Echo slot, making it a solid choice for
                  Coordinated Attack-focused resonators looking to use the 5P
                  Empyrean Anthem Echo set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'mortefi' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Empyrean Anthem" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers an unconditional 10% Energy Regen% and 80% Coordinated
                DMG boost. Also grants a passive supportive effect which
                triggers when the wearer of this set critically strikes with a
                Coordinated attack - this effect grants the on field Resonator
                an ATK% boost regardless if they're the wearer of the set or
                not.{' '}
                <strong>
                  The set is better than Moonlit Clouds if and only if the
                  equipped character's Echoes have endgame investment (with 9 or
                  10 CRIT substats total) and the character has a 5-star weapon
                  equipped. If both conditions aren't met, Moonlit Clouds is a
                  better choice.{' '}
                </strong>
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry name="Hecate" level={[5]} mode="icon" inline /> -
                  Offers a 40% unconditional Coordinated Attack DMG% boost when
                  placed in the Main Echo slot, making it a solid choice for
                  Coordinated Attack-focused resonators looking to use the 5P
                  Empyrean Anthem Echo set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'yinlin' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>136.74%</p>
              </div>
              <div>
                <WWSet name="Void Thunder" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                If you want to play Yinlin as Main DPS (which isn't really that
                optimal), you can use the Void Thunder set on her and it will
                improve her personal damage output at the cost of the team
                damage.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Tempest Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Resonance Skill damage and are looking to use either the 5P
                  Empyrean Anthem Echo or 5P Void Thunder set bonus.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>133.86%</p>
              </div>
              <div>
                <WWSet name="Empyrean Anthem" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers an unconditional 10% Energy Regen% and 80% Coordinated
                DMG boost. Also grants a passive supportive effect which
                triggers when the wearer of this set critically strikes with a
                Coordinated attack - this effect grants the on field Resonator
                an ATK% boost regardless if they're the wearer of the set or
                not.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Tempest Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Resonance Skill damage and are looking to use either the 5P
                  Empyrean Anthem Echo or 5P Void Thunder set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'jianxin' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Rejuvenating Glow" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Healing% boost and an most
                importantly a 15% ATK% boost for the entire team, lasting 30
                seconds whenever you heal yourself or any of your allies. This
                bonus can be triggered by character abilities and weapon
                effects, is easy to keep active 100% of the time and is a big
                help for all party members.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Fallacy of No Return"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoFelacy}
                </li>
                <li>
                  <WWEchoEntry
                    name="Bell-Borne Geochelone"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBell}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Sierra Gale" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Aero DMG% boost and an additional 30%
                Aero DMG% after using the intro lasting 15 seconds for a grand
                total of 40% additional Aero damage for most of your damage
                window.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerrNM}
                </li>
                <li>
                  <WWEchoEntry
                    name="Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerr}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'danjin' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>1</p>
              </div>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>2</p>
              </div>
              <div>
                <WWSet name="Midnight Veil" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Havoc DMG% boost alongside adding a
                large Havoc Damage hit to the Outro of the wearer. Also grants
                the incoming resonator a Havoc DMG% boost when the wearer uses
                their Outro.{' '}
                <strong>
                  The set is better than Moonlit Clouds if and only if the
                  equipped character's Echoes have endgame investment (with 9 or
                  10 CRIT substats total) and the character has a 5-star weapon
                  equipped. If both conditions aren't met, Moonlit Clouds is a
                  better choice.{' '}
                </strong>
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry name="Lorelei" level={[5]} mode="icon" inline />{' '}
                  - Offers an unconditional 12% Havoc DMG and 12% Basic DMG
                  boost when placed in the Main Echo slot, making it a solid
                  choice for Havoc focused resonators who emphasize Basic damage
                  and are looking to use the 5P Midnight Veil set bonus.
                </li>
                <li>
                  <WWEchoEntry
                    name="Nightmare: Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Havoc DMG and 12% Heavy Attack
                  DMG boost when placed in the Main Echo slot, making it a solid
                  choice for Havoc focused resonators who emphasize Heavy Attack
                  damage and are looking to use the 5P Midnight Veil set bonus.
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Sun-sinking Eclipse" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Havoc DMG% boost and up to an
                additional 30% Havoc DMG% after you land 4 Basic or Heavy
                Attacks for a grand total of 40% additional Havoc damage after
                fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Dreamless"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - One of the strongest burst damage Echoes in the game that
                  also generates a sizeable chunk of Resonance Energy. What
                  makes this Echo strong is that its damage is delivered in a
                  massive 360 degree area around the target it fixates on and is
                  immediately swap cancellable the second it's used with 0
                  downsides. When using this Echo, always use it and immediately
                  switch out so it deals it's damage and finishes its animation
                  while the caster of the Echo is off the field. Due to this
                  fact this Echo is normally used outside of characters burst
                  windows.
                </li>
              </ul>
            </div>
          </>
        )}
        {/* DPS */}
        {(name === 'changli' || name === 'chixia' || name === 'encore') && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Molten Rift" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Fusion DMG% boost and an additional
                30% Fusion DMG% after using a Resonance Skill for a grand total
                of 40% additional Fusion damage.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Inferno Rider"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Fusion DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Fusion focused resonators who emphasize
                  Resonance Skill damage and are looking to use the 5P Molten
                  Rift set bonus.
                </li>
                <li>
                  <WWEchoEntry
                    name="Inferno Rider"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Unlike its Nightmare variant, gives a 12% Basic Attack DMG
                  bonus and 12% Fusion DMG bonus, after being transformed into
                  and its last of 3 hits was dealt, for a duration of 15
                  seconds. This makes this Echo preferable for characters who
                  prefer Basic DMG over Skill DMG, but this option is way more
                  difficult to incorporate in rotations, often requiring Swap
                  Cancelling to be smooth.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'jiyan' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Sierra Gale" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Aero DMG% boost and an additional 30%
                Aero DMG% after using the intro lasting 15 seconds for a grand
                total of 40% additional Aero damage for most of your damage
                window.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerrNM}
                </li>
                <li>
                  <WWEchoEntry
                    name="Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerr}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>91.03%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'aalto' && (
          <>
            <h6>Best Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Sierra Gale" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Aero DMG% boost and an additional 30%
                Aero DMG% after using the intro lasting 15 seconds for a grand
                total of 40% additional Aero damage for most of your damage
                window.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerrNM}
                </li>
                <li>
                  <WWEchoEntry
                    name="Feilian Beringal"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoBerr}
                </li>
              </ul>
            </div>
            <h6 className="with-margin">Special Echo Sets</h6>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Moonlit Clouds" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Energy Regen% boost taking some
                pressure off your gear when aiming at ER breakpoints. Also upon
                using Outro, grants the next character switching in a 22.5% ATK
                boost, amplifying any of your main damage dealers burst windows.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Impermanence Heron"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoHeron}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'carlotta' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Frosty Resolve" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Frosty Resolve grants permanent Resonance Skill DMG% and huge
                temporary Glacio/Resonance Skill DMG% bonuses. Glacio DMG% bonus
                is easily accessible for most characters via a single Resonance
                skill use but temporary Resonance Skill DMG% bonus is only
                suitable for characters capable of using their Resonance
                Liberation either frequently and or multiple times at once.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Sentry Construct"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - The Sentry Construct is the most effective Main echo choice
                  for Frosty Resolve users thanks to its passive 12% Glacio and
                  Resonance Skill DMG% bonuses which are active at all times.
                  The transformation ability of Sentry Construct is also
                  powerful, swap cancellable, usable in the air and accessible
                  more often than other Echoes, thanks to its ability to
                  recharge cooldown when the user activates their Resonance
                  Liberation. Carlotta can recharge the Sentry Construct more
                  quickly than most with her multi use Resonance Liberation. The
                  ability to Multi-Use her Liberation is also incredibly helpful
                  for stacking and maintaining the 5P Frost Resolve set effect -
                  she also deals mostly Resonance Skill damage making both
                  Sentry Construct and the set bonuses ideal for her.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>87.36%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>80.22%</p>
              </div>
              <div>
                <WWSet name="Freezing Frost" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Glacio DMG% boost and up to an
                additional 30% Glacio DMG% after you land 3 Basic or Heavy
                Attacks for a grand total of 40% additional Glacio damage after
                fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Lumiscale Construct"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - A solid swap cancellable option for resonators looking to
                  use the 5P Freezing Frost set. Falls behind other Echo sets
                  best options but is a decent choice when considering the slim
                  pickings of the Freezing Frost echo line.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'lingyang' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>98.22%</p>
              </div>
              <div>
                <WWSet name="Frosty Resolve" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Frosty Resolve grants permanent Resonance Skill DMG% and huge
                temporary Glacio/Resonance Skill DMG% bonuses. Glacio DMG% bonus
                is easily accessible for most characters via a single Resonance
                skill use but temporary Resonance Skill DMG% bonus is only
                suitable for characters capable of using their Resonance
                Liberation either frequently and or multiple times at once.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Sentry Construct"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - The Sentry Construct is the most effective Main echo choice
                  for Frosty Resolve users thanks to its passive 12% Glacio and
                  Resonance Skill DMG% bonuses which are active at all times.
                  The transformation ability of Sentry Construct is also
                  powerful, swap cancellable, usable in the air and accessible
                  more often than other Echoes, thanks to its ability to
                  recharge cooldown when the user activates their Resonance
                  Liberation.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>97.96%</p>
              </div>
              <div>
                <WWSet name="Freezing Frost" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Glacio DMG% boost and up to an
                additional 30% Glacio DMG% after you land 3 Basic or Heavy
                Attacks for a grand total of 40% additional Glacio damage after
                fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Lumiscale Construct"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - A solid swap cancellable option for resonators looking to
                  use the 5P Freezing Frost set. Falls behind other Echo sets
                  best options but is a decent choice when considering the slim
                  pickings of the Freezing Frost echo line.
                </li>
                <li>
                  <WWEchoEntry
                    name="Lampylumen Myriad"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoLampy}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'xiangli-yao' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Void Thunder" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Electro DMG% boost and up to an
                additional 30% Electro DMG% after you land a combination of 2
                Heavy Attacks or Resonance Skills for a grand total of 40%
                additional Electro damage after fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Thundering Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Liberation
                  DMG boost when placed in the main Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Liberation damage and are looking to use the 5P Void Thunder
                  set bonus.
                </li>
                <li>
                  <WWEchoEntry
                    name="Nightmare: Tempest Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Resonance Skill damage and are looking to use either the 5P
                  Empyrean Anthem Echo or 5P Void Thunder set bonus.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>92.12%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                The calculations for this set assume you're not swaping during
                the combo. If you will swap, the set performance drops (to
                89.26%).
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'calcharo' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Void Thunder" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Electro DMG% boost and up to an
                additional 30% Electro DMG% after you land a combination of 2
                Heavy Attacks or Resonance Skills for a grand total of 40%
                additional Electro damage after fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Thundering Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Liberation
                  DMG boost when placed in the main Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Liberation damage and are looking to use the 5P Void Thunder
                  set bonus.
                </li>
                <li>
                  <WWEchoEntry
                    name="Nightmare: Tempest Mephis"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Electro DMG and 12% Resonance
                  Skill DMG boost when placed in the Main Echo slot, making it a
                  solid choice for Electro focused resonators who emphasize
                  Resonance Skill damage and are looking to use either the 5P
                  Empyrean Anthem Echo or 5P Void Thunder set bonus.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>93.04%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                The calculations for this set assume you're not swaping during
                the combo. If you will swap, the set performance drops (to
                88.81%).
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'jinhsi' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Celestial Light" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Spectro DMG% boost and an additional
                30% Spectro DMG% after using the intro lasting 15 seconds for a
                grand total of 40% additional Spectro damage for most of your
                damage window.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry name="Jué" level={[5]} mode="icon" inline /> is
                  Jinhsi's best Echo. Jué deal's tremendous bonus damage with 0
                  field time or swap commitment due to them being a summonable
                  Echo instead of transformation one. This gives Jué a
                  significant edge compared to all other top Spectro echoes. Jué
                  should be used right before Jinhsi's standard burst damage
                  rotation as he grants her a 16% Resonance Skill damage bonus
                  for 15 seconds - with only a 20 seconds cooldown you can have
                  this buff up most of the time. Jué also has additional Synergy
                  with Jinhsi as their constant DoT damage builds Jinhsi's
                  Incandescence, allowing her to gain some extra stacks when
                  she's not otherwise satisfying the Spectro trigger for the
                  Forte.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'camellya' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Sun-sinking Eclipse" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Havoc DMG% boost and up to an
                additional 30% Havoc DMG% after you land 4 Basic or Heavy
                Attacks for a grand total of 40% additional Havoc damage after
                fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Crownless"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Havoc DMG and 12% Basic Attack
                  DMG boost when placed in the Main Echo slot, making it a solid
                  choice for Havoc focused resonators who emphasize Basic Attack
                  damage and are looking to use the 5P Sunk-Sinking Eclipse set
                  bonus.
                </li>
              </ul>
            </div>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>94.58%</p>
              </div>
              <div>
                <WWSet name="Lingering Tunes" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional ATK% boost and an additional 5% ATK%
                for every 1.5 seconds the equipped character has been in combat
                - up to 20% after 6 seconds, totalling 30% additional ATK% after
                fully ramped. This set almost almost loses out to most
                characters signature elemental sets, but is generically usable
                across all damage characters regardless of their element.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - {infoMech}
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'rover-havoc' && (
          <>
            <div className={`single-item ${element}`}>
              <div>
                <WWSet name="Sun-sinking Eclipse" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Havoc DMG% boost and up to an
                additional 30% Havoc DMG% after you land 4 Basic or Heavy
                Attacks for a grand total of 40% additional Havoc damage after
                fully ramped.
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Dreamless"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - One of the strongest burst damage Echoes in the game that
                  also generates a sizeable chunk of Resonance Energy. What
                  makes this Echo strong is that its damage is delivered in a
                  massive 360 degree area around the target it fixates on and is
                  immediately swap cancellable the second it's used with 0
                  downsides. When using this Echo, always use it and immediately
                  switch out so it deals it's damage and finishes its animation
                  while the caster of the Echo is off the field. Due to this
                  fact this Echo is normally used outside of characters burst
                  windows.
                </li>
              </ul>
            </div>
          </>
        )}
        {name === 'phoebe' && (
          <>
            <div className={`single-item ${element}`}>
              <div className={`percentage`}>
                <p>100%</p>
              </div>
              <div>
                <WWSet name="Eternal Radiance" mode="profile" />
              </div>
            </div>
            <div className="information">
              <p>
                Offers a 10% unconditional Spectro DMG% boost, a 20% CRIT RATE
                buff for 15 seconds after inflicting Spectro Frazzle and further
                15% Spectro DMG% boost for 15 seconds after attacking an enemy
                that has 10 Spectro Frazzle stacks. For characters that have the
                capability to inflict Spectro Frazzle in their kit the 20% CRIT
                RATE is all but permanant, while the 15% Spectro DMG boost
                should maintain decent uptime in Spectro Frazzle focused teams.
                Generally the best in slot set for all characters capable of
                inflicting Spectro Frazzle.{' '}
                <strong>
                  When playing Phoebe in Absolution, she will require the
                  assistance of her team in order to activate the 15% Spectro
                  DMG boost tied to hitting enemies with at least 10 stacks of
                  Spectro Frazzle - as in this state she cannot achieve that
                  herself.
                </strong>
              </p>
              <p>
                Below you can find Main Echo options that you should be using
                with the set:
              </p>
              <ul className="ww-echo-list">
                <li>
                  <WWEchoEntry
                    name="Nightmare: Mourning Aix"
                    level={[5]}
                    mode="icon"
                    inline
                  />{' '}
                  - Offers an unconditional 12% Spectro DMG boost when active in
                  a resonators Main Echo Slot and gains a 100% DMG bonus to
                  attack against enemies inflicted by Spectro Frazzle. This echo
                  is the go to choice for most resonators capable of inflicting
                  Spectro Frazzle, who are focusing on damage and are looking to
                  use the 5P Eternal Radiance set bonus.
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};
